import i18n from '@/i18n'

export const donutChartData1 = {
  type: 'doughnut',
  data: {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: i18n.t('dashboard.usersCreatedPerMonth'),
        data: [23, 55, 67, 27, 14],
        backgroundColor: [
          '#3f51b5',
          '#00897b',
          '#e91e63',
          '#3849aa',
          '#4caf50'
        ]
      }
    ]
  },
  options: {
    responsive: true,
    legend: {
      position: 'top',
      display: false
    },
    title: {
      display: false,
      text: i18n.t('dashboard.donutChart', { number: 2 })
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }

  }
}

export default donutChartData1
