import Chart from 'chart.js'
import { lineChartData3 } from '../mock-chart-data/lineChart3'
import { barChartData2 } from '../mock-chart-data/barChart2'
import { donutChartData1 } from '../mock-chart-data/donutChart1'
// import i18n from '@/i18n'

const exhale = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {

  name: 'FirstChartSetComponent',
  components: {
  },
  data: () => ({
    thirdLineChartData: lineChartData3,
    secondBarChartData: barChartData2,
    firstDonutChartData: donutChartData1,
    checking: false,
    heartbeats: [],
    labels: [
      '12am',
      '3am',
      '6am',
      '9am',
      '12pm',
      '3pm',
      '6pm',
      '9pm'
    ],
    value: [
      200,
      675,
      410,
      390,
      310,
      460,
      250,
      240
    ],
    minutesAgo: 26
  }),
  beforeCreate () {
  },
  created () {
    this.takePulse(false)
  },
  beforeMount () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.newChart('third-line-chart', this.thirdLineChartData)
    this.newChart('second-bar-chart', this.secondBarChartData)
    this.newChart('first-donut-chart', this.firstDonutChartData)
    // const pieChartData = {
    //   type: 'pie',
    //   data: {
    //     labels: [i18n.t('dashboard.inactive'), i18n.t('dashboard.active')],
    //     datasets: [
    //       {
    //         data: [23, 77],
    //         backgroundColor: [
    //           '#3f51b5',
    //           '#4caf50'
    //         ]
    //       }
    //     ]
    //   },
    //   options: {
    //     legend: {
    //       position: 'top',
    //       display: false
    //     },
    //     responsive: true,
    //     animation: {
    //       animateScale: true,
    //       animateRotate: true
    //     }
    //   }
    // }
    // this.newChart('work-donut-chart', pieChartData)
    // const donutChartData = {
    //   type: 'doughnut',
    //   data: {
    //     labels: [i18n.t('dashboard.inactive'), i18n.t('dashboard.active')],
    //     datasets: [
    //       {
    //         data: [23, 77],
    //         backgroundColor: [
    //           '#0097a7',
    //           '#c2185b'
    //         ]
    //       }
    //     ]
    //   },
    //   options: {
    //     legend: {
    //       position: 'top',
    //       display: false
    //     },
    //     responsive: true,
    //     animation: {
    //       animateScale: true,
    //       animateRotate: true
    //     }
    //   }
    // }
    // this.newChart('time-donut-chart', donutChartData)
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  computed: {
    avg () {
      const sum = this.heartbeats.reduce((acc, cur) => acc + cur, 0)
      const length = this.heartbeats.length
      if (!sum && !length) return 0
      return Math.ceil(sum / length)
    }
  },
  methods: {
    heartbeat () {
      return Math.ceil(Math.random() * (120 - 80) + 80)
    },
    async takePulse (inhale = true) {
      this.checking = true

      inhale && await exhale(1000)

      this.heartbeats = Array.from({ length: 20 }, this.heartbeat)

      this.checking = false
    },
    radioButtonColor (index) {
      return this.colors[index]
    },
    newChart (chartId, chartData) {
      const ctx = document.getElementById(chartId)
      const newChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
      console.log(newChart)
    },
    selectChartSet (number) {
      if (this.radioGroupChart === number) {
        return true
      } else {
        return false
      }
    }
  }
}
