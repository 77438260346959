import i18n from '@/i18n'

export const barChartData2 = {
  type: 'bar',
  data: {
    labels: ['Auto', 'Camioneta', 'Cosechadora', 'Tractor', 'Ambulancia', 'Patrullero', 'Camión', 'Moto'],
    datasets: [
      {
        label: i18n.t('dashboard.vehicleNumber'),
        data: [0, 0, 1, 2, 67, 62, 27, 14],
        backgroundColor: 'rgba(124, 215,132,.5)',
        yAxisID: 'y-axis-1'
      },
      {
        label: i18n.t('dashboard.eventsAverage'),
        data: [46, 68, 68.5, 74.3, 139.8, 16.4, 89.7, 41.2],
        backgroundColor: 'rgba(71, 183,132,.5)',
        yAxisID: 'y-axis-2'
      }
    ]
  },
  options: {
    responsive: true,
    title: {
      display: false,
      text: i18n.t('dashboard.barChart', { number: 2 })
    },
    tooltips: {
      mode: 'index',
      intersect: true
    },
    scales: {
      yAxes: [{
        type: 'linear',
        display: false,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: false
        }
      }, {
        type: 'linear',
        display: false,
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          drawOnChartArea: false
        }
      }],
      xAxes: [{
        display: false,
        gridLines: {
          display: false
        }
      }]
    }
  }
}

export default barChartData2
