import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"div-container"},[_c(VRow,{staticClass:"pa-0 mb-2"},[_c(VCol,{attrs:{"cols":"12","sm":"7"}},[_c(VCard,{staticClass:"pa-2",attrs:{"color":"secondary"}},[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-12",attrs:{"color":_vm.checking ? 'info' : 'success',"size":"64"},on:{"click":_vm.takePulse}},[_vm._v(" speed ")]),_c(VRow,{attrs:{"align":"start"}},[_c('div',{staticClass:"caption grey--text text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.speed'))+" ")]),_c('div',[_c('span',{staticClass:"display-2 font-weight-black",domProps:{"textContent":_vm._s(_vm.avg || '—')}}),(_vm.avg)?_c('strong',[_vm._v("KM/H")]):_vm._e()])])],1),_c(VSheet,{attrs:{"color":"transparent"}},[_c(VSparkline,{key:String(_vm.avg),attrs:{"smooth":16,"gradient":['#f72047', '#ffd200', '#1feaea'],"line-width":3,"value":_vm.heartbeats,"auto-draw":"","stroke-linecap":"round"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VCard,{staticClass:"pa-2",attrs:{"color":"secondary"}},[_c('canvas',{attrs:{"id":"second-bar-chart"}})])],1)],1),_c(VRow,{staticClass:"pa-0"},[_c(VCol,{attrs:{"cols":"12","sm":"7"}},[_c(VCard,{staticClass:"pa-2",attrs:{"color":"secondary"}},[_c('canvas',{attrs:{"id":"third-line-chart"}})])],1),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VCard,{staticClass:"pa-2",attrs:{"color":"secondary"}},[_c('canvas',{attrs:{"id":"first-donut-chart"}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }