import i18n from '@/i18n'

export const lineChartData3 = {
  type: 'line',
  data: {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: i18n.t('dashboard.vehicleNumber'),
        fill: false,
        backgroundColor: '#7953d2',
        borderColor: '#4527a0',
        data: [0, 2, 1, 2, 78, 62, 99, 36],
        borderWidth: 3
      },
      {
        label: i18n.t('dashboard.eventsAverage'),
        fill: false,
        borderDash: [5, 5],
        data: [46, 78, 58.5, 84.3, 19.8, 16.4, 89.7, 4.2],
        backgroundColor: 'rgba(71, 183,132,.5)',
        borderColor: '#47b784',
        borderWidth: 3
      },
      {
        label: i18n.t('dashboard.helpCalls'),
        fill: true,
        data: [21, 32, 4.5, 45.3, 1.8, 10.4, 9.7, 41.2],
        backgroundColor: '#56c8d8',
        borderColor: '#0097a7'
      }
    ]
  },
  options: {
    responsive: true,
    title: {
      display: false,
      text: i18n.t('dashboard.combinedLineChart')
    },
    tooltips: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        display: true,
        scaleLabel: {
          display: true,
          labelString: i18n.t('dashboard.month')
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        display: true,
        scaleLabel: {
          display: true,
          labelString: i18n.t('dashboard.value')
        }
      }]
    }
  }
}

export default lineChartData3
